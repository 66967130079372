import { setRemoteDefinitions } from '@nx/angular/mf';

fetch('/assets/module-federation.manifest.json')
  .then(async (res) => res.json())
  .then((definitions) => {
    setRemoteDefinitions(definitions); 
  })
  .then(async () => import('./bootstrap').catch((err) => {
    console.error(err); 
  }));
